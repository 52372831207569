import Api from "@/service/api";

export default class UserService {
    users() {
        console.log("users")
        return Api().get("/users").then(res => res.data);
    }
    user(userId) {
        return Api().get("/user/" + userId).then(res => res.data);
    }
    create(user) {
        return Api().post("/user", user).then(res => res.data);
    }
    update(user) {
        return Api().put("/user/" + user.userId, user).then(res => res.data);
    }
    delete(user) {
        return Api().delete("/user/" + user.userId).then(() => user);
    }

}
