<script>
  import AkLabel from '@components/general/AkLabel';

  export default {
    components: {AkLabel},
    inject: ['pDisabled'],
    props: {
      label: {
        type: String,
        required: true,
      },
      modelValue: {},
      placeholder: {
        type: String,
        required: false,
      },
      className: {
        type: String,
        required: false,
        default: 'col-md-4',
      },
      validator: {
        type: Object,
        required: false,
      },
      submitted: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      toggleMask: {
        type: Boolean,
        required: false,
        default: true,
      },
      showStrongIndicator: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    methods: {},
    computed: {
      currentValue: {
        get: function () {
          return this.modelValue;
        },
        set: function (val) {
          this.$emit('update:modelValue', val);
        },
      },
      getPlaceholder() {
        return this.placeholder ? this.placeholder : this.label;
      },
      alreadySubmitted() {
        if (this.submitted !== undefined) return this.submitted;
        if (this.pSubmitted !== undefined) return this.pSubmitted;
        return false;
      },
      isDisabled() {
        if (this.disabled !== undefined) return this.disabled;
        if (this.pDisabled !== undefined) return this.pDisabled;
        return false;
      },
      hasValidator() {
        return this.validator !== undefined;
      },
      isInvalid() {
        return this.validator !== undefined && this.validator.$invalid;
      },
      isRequired() {
        return this.validator !== undefined && this.validator.required !== undefined;
      },
      isValidatorRequired() {
        return (
          this.validator !== undefined &&
          this.validator.required !== undefined &&
          this.validator.required.$invalid &&
          this.alreadySubmitted
        );
      },
      isValidatorMinMaxLength() {
        return (
          this.validator !== undefined &&
          (this.validator.minLength !== undefined || this.validator.maxLength !== undefined) &&
          (this.validator.minLength.$invalid || this.validator.maxLength.$invalid) &&
          this.alreadySubmitted
        );
      },
    },
  };
</script>

<template>
  <div :class="this.className" class="form-group">
    <ak-label :required="isRequired">{{ this.label }}</ak-label>

    <Password
      v-model="currentValue"
      :class="{'p-invalid': isInvalid && alreadySubmitted}"
      :disabled="isDisabled"
      :placeholder="getPlaceholder"
      :toggleMask="toggleMask"
      :feedback="showStrongIndicator"
      inputClass="form-control" />

    <small v-if="isValidatorRequired && alreadySubmitted" class="p-error">
        Ce champ est requis
    </small>

    <!--<small v-if="isValidatorMinMaxLength && alreadySubmitted" class="p-error">{{
      $t('min_max_length', [
        $t('company.postalCode'),
        this.validator.minLength.$params.min,
        this.validator.maxLength.$params.max,
      ])
    }}</small> -->
  </div>
</template>
